<template>
  <div class="qrcode-page flex-center" @click="closeModal">
    <div class="modal-content" id="modal-content">
      <div class="top">
        <div class="close-btn flex-center"><i class="fa fa-times"></i></div>
      </div>

      <div class="body scroll flex-center">
        <h3>Payment details</h3>
         <div class="methods flex">
            <div>Visa</div>
            <div>Master Card</div>
            <div>Paypal</div>
        </div>

       <form>
            <div>
                <label>Card Holder Name</label>
                <div>
                  <input type="text">
                </div>
              </div>
              <div>
                <label>Credit Card Number</label>
                <div>
                  <input type="text">
                </div>
              </div>
              
              <div class="small-box flex">
                <div>
                  <label>Exp Date</label>
                  <div>
                    <input type="text" id="exp-date">
                  </div>
                </div>
                <div>
                  <label>CVV</label>
                  <div>
                    <input type="text" id="cvv">
                  </div>
                </div>
              </div>
              <div class="flex save">
                <div class="checkbox">
                      <!-- Rounded switch -->
                <label class="switch">
                    <input type="checkbox">
                    <span class="slider round"></span>
                </label>
              </div>
                <span class="light-text">Save for future transactions</span>
              </div>
              <div class="flex total">
                <span>Total</span>
                <span class="light-text">$565</span>
              </div>
              <div class="btns">
                <Button @click.prevent="" :style="{ color: '#fff', backgroundColor: '#159661', width: '300px', borderRadius: '30px', padding: '10px' }" text="Pay Now"/>  
                <Button @click.prevent="" :style="{ color: '#fff', backgroundColor: 'red', width: '300px', borderRadius: '30px', padding: '10px' }" text="Cancel"/>
              </div>
          </form>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  components: {
    Button
  },
  data(){
    return {
      classes: ['qrcode-page flex-center', 'close-btn flex-center', 'fa fa-times'],
      formData: {
        image: ''
      },
      img: "https://images.pexels.com/photos/1043474/pexels-photo-1043474.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
    }
  },
  methods: {
    closeModal(e){
      if(this.classes.includes(e.target.className)){
        this.$router.push('/orders/directory')
      }
    }
  }
}
</script>

<style scoped>
    .qrcode-page {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      height: 100%;
      overflow: auto;
      width: 100%;
      background: rgba(0,0,0,0.5);
      padding: 10px 0px;
    }

    .modal-content {
      height: 100%;
      width: 500px;
      background: #fff;
      color: #262626;
      padding: 20px;
      box-shadow: 0 0 6px 6px #00000035;
      /* margin-top: 10rem; */
    }

    .top {
      text-align: right;
      display: flex;
      justify-content: right;
    }

    .close-btn {
      height: 20px;
      width: 20px;
      font-size: 10px;
      border-radius: 100px;
      text-align: center;
      cursor: pointer;
      text-align: right;
      background-color: rgba(0, 0, 0, 0.152);
    }

    .close-btn:hover {
      background-color: rgba(0, 0, 0, 0.042);
    }

    .body {
        flex-direction: column;
        height: 100%;
        overflow: auto;
        padding-top: 30%;
        padding-bottom: 3rem;
    }

    .body h3 {
        margin-bottom: 20px;
    }

    .body > div {
        margin-bottom: 20px;
    }
    /* form  */

    
  .fake-input {
        background: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        padding: 0 10px;
        color: #000;
        border: 1px solid #a7a7a7;
    }

    .fake-input input {
      border: none;
      width: 100%;
      flex: 8;
      font-size: 15px;
    }

    .fake-input div {
      flex: 1;
    }

    form > div {
      margin-bottom: 15px;
    }

    form > div:last-child {
      text-align: center;
      margin-top: 50px;
    }


    form label {
      font-size: 12px;
    }

    form input {
      margin-top: 2px;
      border: 1px solid #037597;
      font-size: 15px;  
      width: 300px;
    }

    .btns {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 120px;
        justify-content: space-between;
        align-items: center
    }

    #exp-date {
      width: 130px;
    }

    #cvv {
      width: 100px;
    }

    .save, .total {
      font-size: 14px;
      font-weight: 600;
    }

    .save {
      padding: 0 20px;
    }

    .total {
      margin-bottom: 100px;
    }

    .light-text {
      color: #979797;
    }
    
    /* The switch - the box around the slider */
    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 29px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 1.9px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        box-shadow: 0 1px 0px 1px #8f8f8f;
    }

    input:checked + .slider {
    background-color: #00b300;
    }

    input:focus + .slider {
    box-shadow: 0 0 1px #00b300;
    }

    input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    }

       /* Rounded sliders */
    .slider.round {
    border-radius: 34px;
    }

    .slider.round:before {
    border-radius: 50%;
    }

    .c1 .round {
        position: relative;
    }

    .c1 .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .c1 .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 10px;
    }

    .c1 .round input[type="checkbox"] {
        visibility: hidden;
    }

    .c1 .round input[type="checkbox"]:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
    }

    .c1 .round input[type="checkbox"]:checked + label:after {
        opacity: 1;
    }
</style>